import React from 'react';
import {
  Link,
  useLocation
} from "react-router-dom";
import logo from '../assets/logo-new-small.png';
import { useAuth } from "./useAuth";
import './nav.less'

export default function Nav () {
  let auth = useAuth();
  let location = useLocation();

  return auth.user ? (
    <div className="nav-container">
      <div className="pad-links">
        <Link to="/">
          <img className="logo" src={ logo } alt="logo" />
        </Link>
        <Link to="/test" className={ (location.pathname === '/test' ? 'selected ' : '') + "tab" }>
          隨堂測驗
        </Link>
        <a href="https://forms.gle/jv7EEkTKj2rYt1am6" target="_blank" rel="noopener noreferrer" className="tab">
          課後問卷
        </a>
      </div>
      <div className="right">
        <span className="name">{ auth.user.name }</span>
        <span className="signout" onClick={() => auth.signout()}>退出</span>
      </div>
    </div>
  ) : null
}