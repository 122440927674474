import React, { Component } from 'react';
import './questionnaire.less';

import background from '../assets/background.jpg'
import heartBg from '../assets/bg-board.png'
import horn from '../assets/horn.png'
import girl from '../assets/girl.png'
import bgmFile from '../assets/bgm.mp3'

import scoreResult1 from '../assets/score_result_1.png'
import scoreResult2 from '../assets/score_result_2.png'
import scoreResult3 from '../assets/score_result_3.png'
import scoreResult4 from '../assets/score_result_4.png'

import dialogClose from '../assets/dialog_ic_close.png'
import iconFB from '../assets/icon-fb.png'
import iconLine from '../assets/icon-line.png'
import iconLink from '../assets/link.png'
import iconSave from '../assets/save-image.png'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import html2canvas from 'html2canvas'
import logo from '../assets/logo-new-small.png';
import loading from '../assets/loading.png';

import {
  FacebookShareButton,
  LineShareButton,
} from 'react-share';

class Questionnaire extends Component {
  constructor(props) {
    super(props);

    this.state = {
      examinations: [
        { // 1
          type: 'radio', // 'radio' 單選 'check' 多選
          question: '哪一個選項符合斷捨離障礙的類型?',
          direction: 'column',
          options: [
            '逃避現實型',
            '執著過去型',
            '擔憂未來型',
            '以上皆是'
          ],
          answer: 3,
        },
        { //2
          type: 'radio',
          question: '哪一項符合「斷」的精神？',
          direction: 'column',
          options: [
            '說服小孩丟掉沒在玩的玩具',
            '一口氣送掉一箱不常穿的衣服',
            '雖然五件五折很划算，但決定只買需要的數量',
            '把臥室清空，讓家裡看起來舒適清爽'
          ],
          answer: 2,
        },
        { // 3
          type: 'radio',
          question: '哪一項「不是」給逃避現實型的建議？',
          direction: 'column',
          options: [
            '限定類別',
            '趁有空時多收一點',
            '多用白色',
            '保持成就感和動力'
          ],
          answer: 1,
        },
        { // 4
          type: 'radio',
          question: '關於執著過去型，哪一項描述是錯誤的？',
          direction: 'column',
          options: [
            '每一樣東西都有感情，很難割捨',
            '整理東西的速度很慢',
            '請別人幫忙把東西丟掉',
            '整理的時候特別容易分心'
          ],
          answer: 2
        },
        { // 5
          type: 'radio',
          question: '哪一項不屬於三步限量法？',
          direction: 'column',
          options: [
            '收納的力量',
            '集中的力量',
            '錢的力量',
            '空間限制數量'
          ],
          answer: 0
        },
        { // 6
          type: 'radio',
          question: '哪一項「不是」斷捨離該遵循的原則？',
          direction: 'column',
          options: [
            '優先整理當季的物品',
            '先整理使用頻率低的物品',
            '容易阿雜的人，可多用白色收納盒',
            '使用暫存箱，應設定期限'
          ],
          answer: 0
        },
        { // 7
          type: 'radio',
          direction: 'column',
          question: '以下敘述何者正確？',
          options: [
            '斷捨離最終追求空無一物',
            '物品「還能用」就該留下來',
            '會重買的東西，才真正會用到',
            '階段性物品要收在不起眼處'
          ],
          answer: 2,
        }, 
        { // 8
          type: 'radio',
          direction: 'column',
          question: '關於斷捨離的描述，哪一項是正確的？',
          options: [
            '旨在達成人、物、空間的平衡',
            '重點在於人，不在物',
            '是一種取捨練習，也可運用在工作中',
            '以上皆是'
          ],
          answer: 3
        }
      ],

      scoreResult: [
        {
          score: 59,
          image: scoreResult1,
          text: '有待加強喔！學渣只要肯努力，也能逆襲變學霸，乖～再去把斷捨離影片看3遍！'
        },
        {
          score: 79,
          image: scoreResult2,
          text: "驚險過關～你就是太低調，不願意多露出一點點鋒芒…再戰一次，勇敢超越自己吧！"
        },
        {
          score: 99,
          image: scoreResult3,
          text: '很不錯喔！你是不拘小節的優秀實力派，相信透過動手練習，你的斷捨離實力一定能攀上峰頂～'
        },
        {
          score: 100,
          image: scoreResult4,
          text: '無可挑剔！你已經完全掌握斷捨離的精髓，天賦這東西真不是誰都有！相信你行動起來一定也是棒棒der'
        }
      ],
      currentPage: 0,
      bgmPlay: true,
      currentStatus: 'cover', // cover, desc test, result
      score: 0,
      optionIndex: ['A', 'B', 'C', 'D', 'E'],
      // colors: ['#fa0d2f', '#0d93fd', '#ff4c0e', '#21b351'],
      colors: ['#F1C924', '#F1C924', '#F1C924', '#F1C924'],
      showShare: false,
      name: '',
      showNameError: false,
      shareUrl: window.location.href,
      shareText: '',
      copied: false,
      loading: false
    }

    this.changeBgmStatus = this.changeBgmStatus.bind(this)
    this.nextPage = this.nextPage.bind(this)
    this.toReChallenge = this.toReChallenge.bind(this)
    this.toShowOff = this.toShowOff.bind(this)
    this.closeShareBlock = this.closeShareBlock.bind(this)
    this.onNameChange = this.onNameChange.bind(this)
    this.fbShareOnclick = this.fbShareOnclick.bind(this)
    this.startExam = this.startExam.bind(this)
    this.toDownloadApp = this.toDownloadApp.bind(this)
    this.saveImage = this.saveImage.bind(this)
  }

  componentDidMount () {
    this.player.play()
  }

  fbShareOnclick() {
    /* eslint-disable */
    FB.ui({
      method: 'share',
      display: 'popup',
      href: this.state.shareUrl,
    }, function(response){});
     /* eslint-enable */
	}

  changeBgmStatus () {
    if (this.state.bgmPlay) {
      this.player.pause()
    } else {
      this.player.play()
    }

    this.setState({
      bgmPlay: !this.state.bgmPlay
    })
  }

  nextPage () {
    if (this.state.currentStatus === 'desc' && !this.state.name) {
      this.setState({
        showNameError: true
      })
      return
    }
    let status = ['cover', 'desc', 'test', 'result']
    let index = status.indexOf(this.state.currentStatus)
    if (index !== status.length - 1) {
      this.setState({
        currentStatus: status[index + 1]
      })
    }
  }

  async getShareUrl (newScore) {
    if (this.state.loading) {
      return
    }

    this.setState({
      loading: true
    })
    const canvas = await this.drawCanvas()
    const dataImg = canvas.toDataURL()
    const blob = this.dataURLtoBlob(dataImg)
    const file = new window.File([blob], 'image.png', { type: 'png' })

    let data = new FormData()
    data.append('file', file)
    data.append('type', 'resource')
    data.append('sync_s3', 1)
    let res = await fetch('https://api.give543.com/api/file/uploadImg', {
      method: 'POST',
      body: data
    });
    let json = await res.json();
    let imgUrl = json ? (json.url || '') : '';
    imgUrl = imgUrl.replace('s3-ap-southeast-1.amazonaws.com/next.give543', 'd2hrw3sjxwwsb1.cloudfront.net')
    data = {
      description: "",
      image: imgUrl,
      title: `我在贈物網—斷捨離隨堂考中獲得${newScore || this.state.score}分，你也來試一下！`,
      url: window.location.href,
    }
    this.setState({
      shareText: `我在贈物網—斷捨離隨堂考中獲得${newScore || this.state.score}分，你也來試一下！`
    })
    res = await fetch('https://api.give543.com/api/shares', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    json = await res.json()

    this.setState({
      shareUrl: json.url,
      loading: false
    })
  }

  async saveImage () {
    const canvas = await this.drawCanvas()
    this.save(canvas)
  }

  dataURLtoBlob (dataurl) {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  }

  save (canvas) {
    const dataImg = canvas.toDataURL()
    const url = URL.createObjectURL(this.dataURLtoBlob(dataImg))

    const a = document.createElement('a')
    a.href = url
    a.download = url.replace(/(.*\/)*([^.]+.*)/ig, '$2').split('?')[0]

    const saveElement = document.createElement('a')
    saveElement.download = 'image.png'
    saveElement.href = url
    const evt = document.createEvent('MouseEvents')
    evt.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null)

    saveElement.dispatchEvent(evt)
  }

  DPR () {
    if (window.devicePixelRatio && window.devicePixelRatio > 1) {
      return window.devicePixelRatio;
    }
    return 1;
  }
  async drawCanvas () {
    document.scrollingElement.scrollTop = 0
    const dom = document.querySelector('#test-result')
    // const canvas = document.createElement('canvas')
    // const scale = this.DPR()
    // canvas.width = width * scale
    // canvas.height = height * scale

    // canvas.style.width = width * scale + 'px'
    // canvas.style.height = height * scale + 'px'
    // canvas.getContext('2d').scale(scale, scale)

    const canvas = await html2canvas(dom, {
      scrollX: -window.scrollX,
      scrollY: -window.scrollY
    })
    return canvas
  }

  chooseOption(optionIndex) {
    let { currentPage, score, examinations } = this.state
    if (currentPage >= examinations.length - 1) {
      let newScore = score + (examinations[currentPage] && examinations[currentPage].answer === optionIndex ? (100/examinations.length) : 0)
      this.setState({
        currentStatus: 'result',
        score: newScore
      })
      return
    }
    this.setState({
      score: score + (examinations[currentPage].answer === optionIndex ? (100/examinations.length) : 0),
      currentPage: currentPage + 1
    })
  }

  getScoreResultIndex (score) {
    for (let result of this.state.scoreResult) {
      if (score <= result.score) {
        return result
      }
    }
  }

  toReChallenge () {
    this.setState({
      score: 0,
      currentPage: 0,
      currentStatus: 'test'
    })
    window.gtag('event', 'click', {
      'event_category': '再次挑戰',
      'event_label': '再次挑戰',
      'value': 0
    })
  }

  toShowOff () {
    this.getShareUrl()
    this.setState({
      showShare: true
    })
    window.gtag('event', 'click', {
      'event_category': '去炫耀',
      'event_label': '去炫耀',
      'value': 0
    })
  }

  closeShareBlock() {
    this.setState({
      showShare: false,
      copied: false
    })
  }

  onNameChange (e) {
    this.setState({
      name: e.target.value,
      showNameError: false
    })
  }

  startExam () {
    this.nextPage()

    window.gtag('event', 'click', {
      'event_category': '開始測試',
      'event_label': '開始測試',
      'value': 1
    })
  }

  toDownloadApp () {
    window.open('https://www.give-circle.com')
    window.gtag('event', 'click', {
      'event_category': '逛逛贈物網',
      'event_label': '逛逛贈物網',
      'value': 1
    })
  }

  renderQuestionPage(examination, index) {
    let pageIndex = index
    return (
      <div className="main-page background" key={index} style={{ display:  this.state.currentStatus === "test" && pageIndex === this.state.currentPage ? 'block' : 'none' }}>
        <img className="background-img" src={background} alt="backgroud" />
        <div className="pad-question">
          <div className="pad-bg">
            <img className="heart-bg" src={heartBg} alt="bg" />
            <img className="horn" src={horn} alt="horn" />
            {/* <img className="alarm-clock" src={alarmClock} alt="alarm" /> */}
            <img className="girl" src={girl} alt="girl" />
            <div className="text">{examination.question}</div>
          </div>
        </div>
        <div className={"pad-answer " + examination.direction}>
          {
            examination.options.map((option, j) => {
              return (
                <div className="answer-option" key={j} onClick={this.chooseOption.bind(this, j)} >
                  {
                    option.image ? (
                      <div className="option-img">
                        <img src={option.image} alt="option"/>
                        {option.text && <span>{option.text}</span>}
                        <span>{this.state.optionIndex[j]}</span>
                      </div>
                    ) : (
                      <div>
                        <div className="option-text" style={{backgroundColor: this.state.colors[j]}}>{this.state.optionIndex[j]}、{option.text || option}</div>
                      </div>
                    )
                  }
                </div>
              )
            })
          }
        </div>
        <div className="question-index">{index + 1}/{ this.state.examinations.length }</div>
      </div>
    )
  }

  renderCover () {
    return (
      <div className="main-page background" style={{ display: this.state.currentStatus === "cover" ? "block" : "none"}}>
        <img className="background-img" src={background} alt="backgroud" />
        <div className="pad-cover">
          <div className="cover-content">
            <div className="examination-title">斷捨離隨堂考</div>
            <div className="sub-title">呼朋引伴，揪同事一起來PK～</div>

            <div className="description">知識是行動的基礎<br/>老師教的你都記住了嗎?<br/>來測測你習得幾成功力～</div>
            <div className="button-wrap">
              <button className="button" onClick={this.nextPage}>考前須知</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderDescription () {
    return (
      <div className="main-page background" style={{ display: this.state.currentStatus === "desc" ? "block" : "none"}}>
        <img className="background-img" src={background} alt="backgroud" />
        <div className="pad-description">
          <div className="section">
            <div className="title">考試說明</div>
            <div>隨堂考共8題，答題時間不限，並有悅耳音樂陪伴，相信能助你超強發揮</div>
          </div>
          <div className="section">
            <div className="title">幾點要求</div>
            <div>1. 一氣呵成，不要中途離開</div>
            <div>2. 嚴禁交頭接耳、左顧右盼以及詢問Google</div>
            <div>3. 不服氣隨時重考</div>
          </div>
          <div className="pad-input-name">
            <span className="desc">請輸入您的姓名或暱稱，開始測試吧~</span>
            <input className="input" maxLength="8" placeholder="請輸入您的姓名或暱稱" onChange={this.onNameChange}/>
            {this.state.showNameError && <span className="error">請先輸入您的姓名或暱稱</span>}
          </div>
          <div className="button-wrap">
            <button className="button" onClick={this.startExam}>開始測驗</button>
          </div>
        </div>
      </div>
    )
  }

  renderResultPage () {
    let result = this.getScoreResultIndex(this.state.score)
    return (
      <div className="main-page background" style={{ display: 'block'}}>
        <img className="background-img" src={background} alt="backgroud" />
        <div className="pad-result">
          <div id="test-result" className="result-wrapper">
            <div className="pad-image">
              <img id="result-image" className="resut-image" src={result.image} alt="img" />
            </div>
            <div className="pad-score">{this.state.name}，你獲得了
              <span className="score">{this.state.score}</span>
              分
            </div>
            <div className="pad-desc">
              <div className="result-text">{result.text}</div>
            </div>
            <div className="pad-logo"><img className="logo" src={logo} alt="logo" /></div>
          </div>
          <div className="buttons">
            {this.state.score !== 100 && <button className="btn" onClick={this.toReChallenge}>不服再戰</button>}
            <button className="btn" onClick={this.toShowOff}>分享測驗</button>
          </div>
          <div className="pad-download">
            <button className="btn" onClick={this.toDownloadApp}>
              逛逛贈物網
            </button>
          </div>
        </div>

        { (<div className="pad-share-mask" style={{display: this.state.showShare ? 'block' : 'none'}}>
          {
            this.state.loading ?
            <div className="pad-share">
              <div className="close" onClick={this.closeShareBlock}>
                <img src={dialogClose} alt="close" />
              </div>
              <div className="pad-loading">
                <img src={loading} className="loading" alt="loading" />
                <span>正在生成分享連結...</span>
              </div>
            </div> :
          <div className="pad-share">
            <div className="close" onClick={this.closeShareBlock}>
              <img src={dialogClose} alt="close" />
            </div>
            <div className="share-text">分享到</div>
            <div className="share-buttons">
              <FacebookShareButton url={this.state.shareUrl} className="share-button">
                <img src={iconFB} alt="fb"/>
                <div className="text">FB</div>
              </FacebookShareButton>
              <LineShareButton url={this.state.shareUrl} className="share-button">
                <img src={iconLine} alt="line"/>
                <div className="text">Line</div>
              </LineShareButton>
              <CopyToClipboard text={`${this.state.shareText} ${this.state.shareUrl}`} onCopy={() => this.setState({copied: true})}>
                <div className="share-button">
                  <img src={iconLink} alt="line"/>
                  <div className="text">{this.state.copied ? '已複製' : '複製連結'}</div>
                </div>
              </CopyToClipboard>
              <div className="share-button" onClick={ this.saveImage }>
                <img src={iconSave} alt="line"/>
                <div className="text">保存圖片</div>
              </div>
            </div>
          </div>
          }
        </div>
        )}
      </div>
    )
  }

  render() {
    return (
      <div className="questionnaire">
        <div className="bgm-btn bgm-anim" style={{animationPlayState: this.state.bgmPlay ? "running" : "paused"}} onClick={this.changeBgmStatus}>
          <audio ref={ref => this.player = ref} src={bgmFile} loop preload="true"></audio>
        </div>
        {
          this.renderResultPage()
        }
        {
          this.state.examinations.map((examination, index) => {
            return this.renderQuestionPage(examination, index)
          })
        }
        {
          this.renderDescription()
        }
        {
          this.renderCover()
        }
      </div>
    )
  }
}

export default Questionnaire;
