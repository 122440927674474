
import React, { useState } from "react";
import {
  useHistory,
  useLocation
} from "react-router-dom";
import { useAuth } from "../components/useAuth";
import './login.less';

export default function LoginPage() {
  let history = useHistory();
  let location = useLocation();
  let auth = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMessage] = useState('');

  let { from } = location.state || { from: { pathname: "/" } };
  let login = (username, password) => {
    auth.signin(username, password)
      .then((user) => {
        window.gtag('event', 'login_complete');
        window.gtag('config', window.GA_MEASUREMENT_ID, {
          'user_id': user.name
        });
        history.replace(from);
      })
      .catch((err) => {
        console.log(err)
        setErrorMessage(err);
      })
  };

  return (
    <div className="login-wrapper">
      <h2 className="login-title">GC贈物網</h2>
      <form className="login-form">
        <div className="login-item">
          <input
            type="text"
            autoComplete="off"
            required
            className={'login-item-input ' + (username ? '' : 'titleAnimation')}
            onChange={ (e) => { setUsername(e.target.value); setErrorMessage(''); } }
          />
          <label
            className={'login-item-title ' + (username ? 'animatedPosition' : '')}
          >
            帳號
          </label>
          <i></i>
        </div>
        <div className="login-item">
          <input
            type="password"
            autoComplete="new-password"
            required
            className={'login-item-input ' + (password ? '' : 'titleAnimation')}
            onChange={ (e) => { setPassword(e.target.value); setErrorMessage('') } }
          />
          <label
            className={'login-item-title ' + (password ? 'animatedPosition' : '')}
          >
            密碼
          </label>
          <i></i>
        </div>
        <button
          type="button"
          className="login-submit" 
          disabled={!(username && password)}
          onClick={ () => login(username, password) }>登入</button>
        <div className="error-message">{ errorMsg }</div>
      </form>
    </div>
  );
}
