export const videoData = [
  {
    id: '755379910',
    image: 'https://d2hrw3sjxwwsb1.cloudfront.net/back-bg/courses/755379910.jpg',
    title: '一、斷捨離是什麼?',
    duration: '06:10',
    url: 'https://player.vimeo.com/video/755379910?h=2f48e525b4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
  },
  {
    id: '755400820',
    image: 'https://d2hrw3sjxwwsb1.cloudfront.net/back-bg/courses/755400820.jpg',
    title: '二、逃避現實型',
    duration: '05:40',
    url: 'https://player.vimeo.com/video/755400820?h=3bc780028f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
  },
  {
    id: '755389725',
    image: 'https://d2hrw3sjxwwsb1.cloudfront.net/back-bg/courses/755389725.jpg',
    title: '三、執著過去型',
    duration: '05:16',
    url: 'https://player.vimeo.com/video/755389725?h=2c72ef04a4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
  },
  {
    id: '755392201',
    image: 'https://d2hrw3sjxwwsb1.cloudfront.net/back-bg/courses/755392201.jpg',
    title: '四、擔憂未來型',
    duration: '05:30',
    url: 'https://player.vimeo.com/video/755392201?h=3692da9fde&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
  },
  {
    id: '755394238',
    image: 'https://d2hrw3sjxwwsb1.cloudfront.net/back-bg/courses/755394238.jpg',
    title: '五、獨家首創斷捨離心法',
    duration: '06:58',
    url: 'https://player.vimeo.com/video/755394238?h=4445a566c2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
  },
  {
    id: '755398525',
    image: 'https://d2hrw3sjxwwsb1.cloudfront.net/back-bg/courses/755398525.jpg',
    title: '六、贈前必看',
    duration: '08:10',
    url: 'https://player.vimeo.com/video/772274774?h=9e027b3a97&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
  }
]