import React, { useContext, createContext, useState } from "react";

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export function useAuth() {
  return useContext(authContext);
}

const fakeAuth = {
  isAuthenticated: false,
  userList: [
    {
      username: 'info@give-circle.com',
      password: 'gc82817585',
      name: '贈物網',
      expiredAt: null
    },
    {
      username: '18997007',
      password: '18997007',
      name: '逹欣工程',
      expiredAt: '2021/09/30 23:59:59'
    },
    {
      username: '1477',
      password: '1477',
      name: '聚陽實業',
      startAt: '2021/12/03 00:00:00',
      expiredAt: '2022/01/05 23:59:59'
    },
    {
      username: 'makalot',
      password: 'makalot',
      name: '聚陽實業',
      startAt: '2021/12/03 00:00:00',
      expiredAt: '2022/01/05 23:59:59'
    },
    {
      username: '97179430',
      password: '97179430',
      name: '遠傳電信',
      startAt: '2021/12/29 00:00:00',
      expiredAt: '2022/01/04 18:00:00'
    },
    {
      username: '80333183',
      password: '80333183',
      name: '永豐金控',
      startAt: '2022/06/21 00:00:00',
      expiredAt: '2022/06/24 23:59:59'
    },
    {
      username: '03358805',
      password: '03358805',
      name: '保誠人壽',
      startAt: '2022/11/07 00:00:00',
      expiredAt: '2023/01/01 23:59:59'
    },
    {
      username: '10617383',
      password: '10617383',
      name: '臺北大學',
      startAt: '2023/01/03 00:00:00',
      expiredAt: '2023/12/31 23:59:59'
    },
    {
      username: '8414test',
      password: '8414test',
      name: '友達光電',
      startAt: '2023/03/08 00:00:00',
      expiredAt: '2023/03/15 23:59:59',
    },
    {
      username: '89858152',
      password: '89858152',
      name: '泰碩電子',
      startAt: '2023/07/18 00:00:00',
      expiredAt: '2023/09/01 23:59:59'
    },
    {
      username: '45877096',
      password: '45877096',
      name: '泓德能源',
      startAt: '2023/12/18 00:00:00',
      expiredAt: '2024/02/01 23:59:59'
    },
    {
      username: '86517321',
      password: '86517321',
      name: '凱基銀行',
      startAt: '2024/04/09 00:00:00',
      expiredAt: '2024/04/26 23:59:59'
    }
  ],
  signin(username, password) {
    return new Promise((resolve, reject) => {
      let user = fakeAuth.userList.find(item => item.username === username)
      if (!user) {
        return reject('帳號不存在')
      }

      if (user.password !== password) {
        return reject('密碼錯誤')
      }

      if (user.startAt && (new Date().getTime() - new Date(user.startAt.replaceAll('-', '/')).getTime() < 0)) {
        // eslint-disable-next-line no-multi-str
        return reject('帳號尚未生效，請於有效期間登入；如有疑問，請聯絡贈物網專案企劃組 service@give-circle.com')
      }

      if (user.expiredAt && (new Date().getTime() - new Date(user.expiredAt.replaceAll('-', '/')).getTime() > 0)) {
        // eslint-disable-next-line no-multi-str
        return reject('帳號已失效，請聯絡贈物網專案企劃組 service@give-circle.com')
      }

      fakeAuth.isAuthenticated = true;
      setTimeout(() => resolve(user), 100); // fake async
    });
  },
  signout(cb) {
    fakeAuth.isAuthenticated = false;
    setTimeout(cb, 100);
  }
};

export function useProvideAuth() {
  const [user, setUser] = useState(process.env.NODE_ENV === 'development' ? fakeAuth.userList[0] : null);

  const signin = (username, password) => {
    return fakeAuth.signin(username, password)
      .then((user) => {
        setUser(user);
        return user;
      })
  };

  const signout = () => {
    return fakeAuth.signout(() => {
      setUser(null);
    });
  };

  return {
    user,
    signin,
    signout
  };
}