import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { ProvideAuth, useAuth } from "./components/useAuth";
import LoginPage from "./pages/login";
import VideoListPage from './pages/video-list';
import VideoDetailPage from "./pages/video-detail";
import Nav from "./components/nav";
import './app.less'
import Questionnaire from "./pages/questionnaire";

export default function App() {
  return (
    <ProvideAuth>
      <Router>
        <div className="container">
          <Nav />
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <div className="body-container">
              <PrivateRoute exact path="/">
                <VideoListPage />
              </PrivateRoute>
              <PrivateRoute path="/video/:id">
                <VideoDetailPage />
              </PrivateRoute>
              <Route path="/test">
                <Questionnaire />
              </Route>
            </div>
          </Switch>
        </div>
      </Router>
    </ProvideAuth>
  );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location, ...rest }) =>
        auth.user ? (
          React.Children.map(children, (child) => {
            return React.cloneElement(child, { location, ...rest, user: auth.user })
          })
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
